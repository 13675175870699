import { ConnectedRouter } from 'connected-react-router';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import AppLayout from '@healthme/core/AppLayout';
import APIErrorProvider from '@healthme/utility/APIErrorProvider';
import AuthRoutes from '@healthme/utility/AuthRoutes';
import ContextProvider from '@healthme/utility/HealthMeProvider';
import HealthMeStyleProvider from '@healthme/utility/HealthMeStyleProvider';
import HealthMeThemeProvider from '@healthme/utility/HealthMeThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';

import configureStore, { history } from './redux/store';

// Sentry.init({
// 	dsn: 'https://f2429b41a60f47de8e6233e74f91b845@o1300309.ingest.sentry.io/6534547',
// 	integrations: [new BrowserTracing()],
// 	tracesSampleRate: 1.0,
// });

const tagManagerArgs = {
	gtmId: 'GTM-MTKZSKC',
};

TagManager.initialize(tagManagerArgs);
const store = configureStore();
const queryClient = new QueryClient();
ReactGA.initialize('G-C1WTBV58HF');
	const isLoggedIn = localStorage.getItem('loggedIn');

	if (
		window.location.pathname.includes('reimbursement') ||
		(window.location.pathname.includes('v2') && isLoggedIn)
	) {
		localStorage.setItem('redirect_url', encodeURI(window.location.pathname));
		window.location.href = `/v2/index.html?assumeRole=network_manager`;
	}

	if (window.location.pathname.includes('v2/sign-in')) {
		window.location.href = `/v2/index.html?signin=true`;
	}




const App = () => (
	<ContextProvider>
		<Provider store={store}>
			<HealthMeThemeProvider>
				<HealthMeStyleProvider>
					<APIErrorProvider>
						<ConnectedRouter history={history}>
							<AuthRoutes>
								<CssBaseline />
								<QueryClientProvider client={queryClient}>
									<AppLayout />
									<ReactQueryDevtools />
								</QueryClientProvider>
							</AuthRoutes>
						</ConnectedRouter>
					</APIErrorProvider>
				</HealthMeStyleProvider>
			</HealthMeThemeProvider>
		</Provider>
	</ContextProvider>
);

export default App;
