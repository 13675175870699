import React from 'react';

export const publicRouteConfig = [
	{
		routes: [
			{
				path: '/reimbursement/:token',
				component: React.lazy(() => import('./Pages/Reimbursement/Index')),
			},
			{
				path: '/payment',
				component: React.lazy(() => import('./Pages/Payment/Index')),
			},
			{
				path: '/invoice',
				component: React.lazy(() => import('./Pages/Payment/Index')),
			},
			{
				path: '/invoice-order-complete',
				component: React.lazy(() => import('./Pages/OrderComplete/Index')),
			},
		],
	},
];
