import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';

import { Popover, Transition } from '@headlessui/react';
import MessageChatModal from '@healthme/core/MessageChat/Index';
import messagesAPI from '@healthme/services/api/messages/Index';
import userService from '@healthme/services/user';
import Alert from '@material-ui/lab/Alert/Alert';

let tabs = [{ label: 'Messages from Healthme', key: 'global' }];
export default function Notifications({}) {
	const user = userService.getUser();
	const [activeTab, setActiveTab] = useState('direct');
	const [messages, setMessages] = useState([]);
	const [global, setGlobal] = useState([]);
	const [direct, setDirect] = useState([]);
	const [messagesCount, setMessagesCount] = useState(0);
	const [totalMessagesCount, setTotalMessagesCount] = useState('250px');
	const [openMessageModal, setOpenMessageModal] = useState(false);
	const [selectedPaymentId, setSelectedPaymentId] = useState('');
	const [selectedNetwork, setSelectedNetwork] = React.useState('');

	React.useEffect(() => {
		// if (user.role == 'admin') {
		// 	tabs = tabs.filter(e => e.key !== 'direct');
		// 	setActiveTab('global');
		// 	return;
		// }
	}, []);

	const { refetch } = useQuery(
		`messages:global`,
		data => {
			return window.location.pathname.includes('onboarding')
				? Promise.resolve({ global: [], direct: [] })
				: messagesAPI.getRaw(`messages/notifications`);
		},
		{
			enabled: user.role !== 'network_group_staff',
			onSuccess: ({ global, direct, unread_count }) => {
				setMessagesCount(unread_count);
				const globalMessages = (global || []).map(item => {
					return {
						id: item.id,
						message: `${item.title} - ${item.text}`,
						receivedTime: moment(item.created_at).fromNow(),
						created_at: item.created_at,
					};
				});
				globalMessages.sort(
					(a, b) => moment(a.created_at).unix() - moment(b.created_at).unix()
				);
				setGlobal(globalMessages);
				let directMessage = (direct || []).map(item => {
					return {
						id: item.id,
						purchaseId: item.purchase_id,
						message: `${item.text}`,
						receivedTime: moment(item.created_at).fromNow(),
						created_at: item.created_at,
						purchase: item.purchase,
					};
				});
				directMessage = directMessage.sort(
					(a, b) => moment(b.created_at).unix() - moment(a.created_at).unix()
				);
				setDirect(directMessage);
				let totalMessages = directMessage.length + globalMessages.length;
				if (totalMessages > 1 && totalMessages < 10)
					setTotalMessagesCount(totalMessages * 60 + 150 + 'px');
				if (totalMessages > 10) setTotalMessagesCount('50vh');
			},
			refetchOnWindowFocus: false,
			refetchInterval: 10000,
		}
	);

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - onTabChange
	 **-------------------------------------------------------------------------------------
	 */
	const onTabChange = tab => {
		setActiveTab(tab);
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - clearMessages
	 **-------------------------------------------------------------------------------------
	 */
	const clearMessages = () => {
		setDirect([]);
		messagesAPI.postRaw({}, `messages/clear`).then();

		return;
	};
	if (user.role === 'network_group_staff') return null;
	return (
		<>
			<Popover className="relative mt-2 mr-5">
				<Popover.Button
					onClick={e => {
						let readMessages = localStorage.getItem('readMessages') || '[]';
						let m = JSON.parse(readMessages) as any;

						localStorage.setItem(
							'readMessages',
							JSON.stringify([...m, ...messages.map(e => e.id)])
						);
						messagesAPI.get('/read').then();
					}}
					className="inline-flex relative items-center text-sm font-medium text-center text-white rounded-lg ring-0 outline-none focus:ring-0 focus:ring-offset-0"
				>
					<img className="w-8 h-8 rounded-full" src="/assets/icons/Bell.svg" alt="" />
					<span className="sr-only">Notifications</span>
					{messagesCount > 0 && user.role !== 'admin' && (
						<div className="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white ring-black dark:border-gray-900">
							{messagesCount}
						</div>
					)}
				</Popover.Button>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-200"
					enterFrom="opacity-0 translate-y-1"
					enterTo="opacity-100 translate-y-0"
					leave="transition ease-in duration-150"
					leaveFrom="opacity-100 translate-y-0"
					leaveTo="opacity-0 translate-y-1"
				>
					<Popover.Panel
						onMouseLeave={() => {
							refetch();
						}}
						className="flex absolute -left-56 z-10 px-4 mt-2 w-screen max-w-max"
					>
						<div className="overflow-hidden flex-auto w-screen max-w-md text-sm leading-6 bg-white rounded-md border-gray-900 ring-1 ring-black shadow-lg ring-gray-900/5">
							<div>
								<div
									className={`overflow-scroll`}
									style={{
										height: totalMessagesCount,
									}}
								>
									{global.length > 0 && (
										<div className="flex relative justify-center py-1 font-semibold text-center bg-gray-200">
											<p>Announcements</p>
										</div>
									)}
									{global.map((message, idx) => {
										return (
											<div
												key={`${idx}-message-${activeTab}`}
												className="grid grid-cols-1 px-4 py-3 border-b hover:bg-gray-100 dark:hover:bg-gray-700"
											>
												<div className="pl-3 w-full">
													<div
														dangerouslySetInnerHTML={{
															__html: message.message,
														}}
														className="text-gray-500 text-sm mb-1.5 dark:text-gray-400"
													></div>
													<div className="text-xs text-blue-600 dark:text-blue-500">
														{message.receivedTime}
													</div>
												</div>
											</div>
										);
									})}
									{direct.length > 0 && (
										<div className="flex relative justify-center py-1 font-semibold text-center bg-gray-200">
											<p>CareNav</p>
											<a
												onClick={clearMessages}
												className="absolute text-xs underline right-2 top-1.5 text-link font-semibold"
											>
												Clear Notifications
											</a>
										</div>
									)}
									{direct.map((message, idx) => {
										return (
											<div
												onClick={() => {
													setSelectedNetwork(message.purchase.network);

													setSelectedPaymentId(message.purchaseId);
													setOpenMessageModal(true);
													setTimeout(() => {
														refetch();
													}, 2500);
													return false;
												}}
												key={`${idx}-message-${activeTab}`}
												className="grid grid-cols-1 px-4 py-3 border-b cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
											>
												<div className="pl-3 w-full">
													<div
														dangerouslySetInnerHTML={{
															__html: message.message,
														}}
														className="text-gray-500 text-sm mb-1.5 dark:text-gray-400"
													></div>
													<div className="text-xs text-blue-600 dark:text-blue-500">
														{message.receivedTime}
													</div>
												</div>
											</div>
										);
									})}
									{global.length === 0 && direct.length === 0 && (
										<div className="flex flex-wrap justify-center mx-5 my-5">
											<Alert className="w-full" color="warning">
												There are no messages
											</Alert>
										</div>
									)}
								</div>
							</div>
						</div>
					</Popover.Panel>
				</Transition>
			</Popover>
			{openMessageModal && (
				<MessageChatModal
					purchaseId={selectedPaymentId}
					openModal={openMessageModal}
					selectedNetwork={selectedNetwork}
					onClose={() => {
						setOpenMessageModal(false);
					}}
				/>
			)}
		</>
	);
}
