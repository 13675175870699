import moment from 'moment';
import InputMask from 'react-input-mask';

import Icon, { Icons } from '@healthme/core/Icon/Index';
import TimePicker from '@healthme/core/timepicker';

//type InputElement = HTMLInputElement | HTMLTextAreaElement;
//type InputChangeEvent = React.ChangeEvent<InputElement>;

interface TextFieldProps {
	value: string;
	label: string;
	onChange: (val: string) => void;
	onFocus?: (val: string) => void;
	onBlur?: (val: string) => void;
	placeholder?: string;
	autoFocus?: boolean;
	name?: string;
	type?: 'email' | 'password' | 'text' | 'number' | 'date' | 'textarea' | 'time';
	textarea?: boolean;
	leftIcon?: Icons;
	rightIcon?: Icons;
	error?: any;
	forwardedRef?: any;
	className?: string;
	readonly?: boolean;
	keyUp?: (e) => void;
	onPaste?: (e) => void;
	width?: 'w-full';
	disabled?: boolean;
	max?: string;
	maxlength?: number;
	inputClasses?: string;
	showMask?: boolean;
	mask?: string;
	pattern?: string;
	keyPress?: (e) => void;
	hideErrors?: boolean;
	rows?: number;
	style?: any;
	min?: string;
}

function triggerInput(enteredName, enteredValue) {
	const input: any = document.getElementById(enteredName);
	const lastValue = input?.value ?? '';
	input.value = enteredValue ?? '';
	const event = new Event('input', { bubbles: true });
	const tracker = input._valueTracker;
	if (tracker) {
		tracker.setValue(lastValue);
	}
	input.dispatchEvent(event);
}

const noop = e => {};

const TextField = ({
	onChange,
	textarea = false,
	hideErrors = false,
	rows = 5,
	style = {},
	...rest
}: TextFieldProps) => {
	const InputElement = textarea ? 'textarea' : 'input';
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - Return
	 **-------------------------------------------------------------------------------------
	 */
	//{({ target: { value } }: InputChangeEvent) => onChange(value)}
	return (
		<div className={`floating-label ${rest.className}`}>
			{rest.leftIcon && <Icon type={rest.leftIcon} classNames="w-4 left-icon" />}
			{!rest.showMask && (
				<>
					<InputElement
						className={`floating-input ${rest?.inputClasses} ${
							rest?.leftIcon?.length && 'left-icon-input'
						} ${rest?.error?.length && 'error'} `}
						type={rest.type}
						placeholder={rest.placeholder}
						name={rest.name}
						id={rest.name}
						value={rest.value}
						onChange={(e: any) => {
							onChange(e);
						}}
						onFocus={(e: any) => {
							if (rest.onFocus) rest.onFocus(e);
						}}
						onBlur={(e: any) => {
							if (rest.onBlur) rest.onBlur(e);
						}}
						onPaste={(e: any) => {
							if (rest.onPaste) rest.onPaste(e);
						}}
						ref={rest.forwardedRef}
						readOnly={rest.readonly ?? false}
						autoComplete="nope"
						onKeyUp={rest?.keyUp || noop}
						disabled={rest.disabled}
						max={rest.max}
						maxLength={rest?.maxlength}
						pattern={rest?.pattern}
						rows={rows}
						style={style}
						onKeyDown={(e: any) => {
							if (rest.keyPress) rest.keyPress(e);
						}}
						min={rest?.min || ''}
					/>
				</>
			)}
			{rest.showMask && (
				<InputMask
					className={`floating-input ${rest?.inputClasses} ${
						rest?.leftIcon?.length && 'left-icon-input'
					} ${rest?.error?.length && 'error'} `}
					type={rest.type}
					placeholder={rest.placeholder}
					name={rest.name}
					id={rest.name}
					value={rest.value}
					onChange={(e: any) => {
						onChange(e);
					}}
					onBlur={(e: any) => {
						if (rest.onBlur) rest.onBlur(e);
					}}
					onPaste={(e: any) => {
						if (rest.onPaste) rest.onPaste(e);
					}}
					ref={rest.forwardedRef}
					readOnly={rest.readonly ?? false}
					autoComplete="nope"
					onKeyUp={rest?.keyUp || noop}
					disabled={rest.disabled}
					max={rest.max}
					mask={rest.mask}
					maxLength={rest?.maxlength}
					pattern={rest?.pattern}
				/>
			)}

			<label
				className={`${rest?.leftIcon?.length && 'left-10 ml-1'} ${
					rest?.placeholder && rest.placeholder.length > 1 && 'placeholder-active'
				}`}
			>
				{rest.label}
			</label>
			{rest.rightIcon && rest.rightIcon === 'Clock' && (
				<TimePicker
					className="input-field"
					theme="Ash"
					placeholder="Start Time"
					time={moment(rest.value, 'hh:mm A').format('HH:mm')}
					onSet={val => {
						triggerInput(rest.name, val.format12);
					}}
				/>
			)}

			{rest.rightIcon && rest.rightIcon !== 'Clock' && (
				<Icon type={rest.rightIcon} classNames="w-4 right-icon" />
			)}
			{rest.error && <Icon type="Alert" classNames="w-4 right-icon icon-red" />}
			{rest.error && !hideErrors && <p className="error-msg">{rest?.error}</p>}
		</div>
	);
};
TextField.defaultProps = {
	inputClasses: '',
	showDate: false,
	onfocus: e => {},
};
export default TextField;
